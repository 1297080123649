.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

}

.splash-screen-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* ************************************************************************ */
.splash-screen-main .loading-dot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;

  animation: runDots 1.2s ease-in-out infinite alternate;
  -webkit-animation: runDots 1.2s ease-in-out infinite alternate;
}
.splash-screen .loading-dot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;

  animation: runDots 1.2s ease-in-out infinite alternate;
  -webkit-animation: runDots 1.2s ease-in-out infinite alternate;
}
@keyframes runDots {
    0% {
      left: -60px;  
      color: #eee;
    }
    50% {
      color: #666;
    }
    100% {
      left: 60px;  
      color: #eee;
    }
  }  
  @-webkit-keyframes runDots {
    0% {
      left: -60px;  
      color: #eee;
    }
    50% {
      color: #666;
    }
    100% {
      left: 60px;  
      color: #eee;
    }
  }
  
  /* ************************************************************************ */
  .splash-screen-main .loading-spin-dot {
    height: 90px;           /* ajusta o diametro do circulo - qto maior, menor o circulo */
    font-size: 100px;       /* ajusta tamanho da bolinha */
    color:skyblue;
    margin-top: -90px; /* move o icone pra cima ou pra baixo */
    margin-left: -25px; 
    position: relative;

    animation: runSpinDots 1.5s ease-in-out infinite ;
    -webkit-animation: runSpinDots 1.0s ease-in-out infinite ;
  }

  .splash-screen-main .loading-spin-dot2 {
    height: 90px;           /* ajusta o diametro do circulo - qto maior, menor o circulo */
    font-size: 100px;       /* ajusta tamanho da bolinha */
    color:skyblue;
    margin-top: -90px; /* move o icone pra cima ou pra baixo */
    margin-left: -25px; 
    position: relative;

    animation: runSpinDots 1.5s ease-out infinite ;
    -webkit-animation: runSpinDots 1.5s ease-out infinite ;
  }

  .splash-screen-main .loading-spin-dot3 {
    height: 90px;           /* ajusta o diametro do circulo - qto maior, menor o circulo */
    font-size: 100px;       /* ajusta tamanho da bolinha */
    color:skyblue;
    margin-top: -90px; /* move o icone pra cima ou pra baixo */
    margin-left: -25px; 
    position: relative;

    animation: runSpinDots 1.5s infinite ;
    -webkit-animation: runSpinDots 1.5s infinite ;
  }

  .splash-screen-main .loading-spin-dot4 {
    height: 90px;           /* ajusta o diametro do circulo - qto maior, menor o circulo */
    font-size: 100px;       /* ajusta tamanho da bolinha */
    color:skyblue;
    margin-top: -90px; /* move o icone pra cima ou pra baixo */
    margin-left: -25px; 
    position: relative;

    animation: runSpinDots 1.6s infinite ;
    -webkit-animation: runSpinDots 1.6s infinite ;
  }

  .splash-screen-main .loading-spin-dot5 {
    height: 90px;           /* ajusta o diametro do circulo - qto maior, menor o circulo */
    font-size: 100px;       /* ajusta tamanho da bolinha */
    color:skyblue;
    margin-top: -90px; /* move o icone pra cima ou pra baixo */
    margin-left: -25px; 
    position: relative;

    animation: runSpinDots 1.3s infinite ;
    -webkit-animation: runSpinDots 1.3s infinite ;
  }

  /************/
  .splash-screen .loading-spin-dot {
    height: 125px; /* ajusta o diametro do circulo */
    font-size: 100px; 
    color:skyblue;
    margin-top: -90px; /*-80px */
    position: relative;

    animation: runSpinDots 1.2s ease-in-out infinite ;
    -webkit-animation: runSpinDots 1.2s ease-in-out infinite ;
  }
  @keyframes runSpinDots {
    0% { -webkit-transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg);}
}  
@-webkit-keyframes runSpinDots {
    0% { -webkit-transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg);}
}

  /* ************************************************************************ */
  /* NAO USADO - por alguma razao nao funciona no Heroku */
  /*style={{ width: '5%', heigth: '5%', alt: 'Saga Systems', opacity:'60%' }}*/
  .splash-screen-main .loading-logo {
    width: 50px; /*5%; */
    height: 50px; /*5%;*/
    opacity: 50%;
    animation: runLogo 1.2s linear infinite alternate;
    -webkit-animation: runLogo 1.2s linear infinite alternate;
  }
  
  .splash-screen .loading-logo {
    width: 50px; /*5%; */
    height: 50px; /*5%;*/
    opacity: 50%;

    /*animation: ease-in-out infinite alternate;
    animation-name: runLogo;
    animation-duration: 1.2s;*/

    animation: runLogo 1.2s linear infinite alternate;
    -webkit-animation: runLogo 1.2s linear infinite alternate;
  }
  
  @keyframes runLogo {
    0% {
      opacity: 10%;
    }
    50% {
      opacity: 30%;
    }
    100% {
      opacity: 60%;
    }
  }  

  @-webkit-keyframes runLogo {
    0% {
      opacity: 10%;
    }
    50% {
      opacity: 30%;
    }
    100% {
      opacity: 60%;
    }
  }
  